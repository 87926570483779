import React, { Component } from "react";
import "../styles/default.scss";
import "../styles/normalize.scss";
import "./App.css";
import "@klumpp/tools/dist/klumpp-tools.css";
import { createStore } from "redux";
import reducer from "../utils/Reducer";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Auth, AuthCallback, BackendInterface, RouterHistory, ToastContainer, CookieBanner, Cookie, Logout } from "@klumpp/tools";
import Dashboard from "../components/dashboard/Dashboard";
import Error from "../components/error/Error";
import OverviewPage from "../components/global/OverviewPage";

window.reduxStore = createStore(reducer);
interface IAppState {
	auth: Auth;
}
class App extends Component<any, IAppState> {
	constructor(props: any) {
		super(props);
		window.auth = new Auth(
			this.props.history,
			process.env.REACT_APP_AUTH0_DOMAIN as string,
			process.env.REACT_APP_AUTH0_CLIENT_ID as string,
			process.env.REACT_APP_AUTH0_CALLBACK_URL as string,
			process.env.REACT_APP_AUTH0_AUDIENCE as string
		);
		window.routerHistory = this.props.history;
		window.backend = new BackendInterface(window.auth,
			RouterHistory,
			process.env.REACT_APP_API_URL as string,
			{ history: RouterHistory as any, errorPath: "/error" });
		window.cookies = new Cookie()
		this.state = {
			auth: window.auth,
		};
	}

	changeTitle = (currentPageName: string) => {
		let title = document.querySelector("title");
		if (title) {
			title.innerHTML = currentPageName;
		}
	};

	componentDidMount() { }

	render() {
		return (
			<div className="app">
				<ToastContainer />
				<CookieBanner cookieClass={window.cookies} history={RouterHistory}></CookieBanner>

				<Switch>
					<Route exact path="/" component={OverviewPage} />
					<Route path="/error" render={(props) => <Error></Error>} />
					<Route
						path="/callback"
						render={(props) => <AuthCallback auth={this.state.auth} />}
					/>
					<Route path="/login" render={(props) => <Redirect to="/" />} />
					<Route path="/logout" render={(props) => <Logout to="/" history={RouterHistory} auth={window.auth} />} />

					<Route
						path="/dashboard"
						render={(props) => <Dashboard {...props}></Dashboard>}
					/>
				</Switch>
			</div>
		);
	}
}

export default withRouter(App);
