import React, { Component } from "react";
import {Button, Dialog, Table } from "@klumpp/tools";

export interface TablePageProps { 
	table: string;
	getUrl?: string;
	postUrl?: string;
	deleteUrl?: string;
	order?: string[];
    isBase?: boolean;
}

export interface TablePageState {
	data: any;
	columns: any[];
	title: string;
	message: string;
}

export default class TablePage extends Component< 
        TablePageProps,
        TablePageState
	> {
	state = { columns: [], data: [], title: "", message: "" };
	tableRef = React.createRef<Table>();
	dialogRef = React.createRef<Dialog>();
	url = "/tables/";

	loadTable = () => {
		this.tableRef.current && this.tableRef.current.setLoading(true);
		window.backend.getData(this.props.getUrl?this.props.getUrl:
			`${this.url}${this.props.table}/${this.props.order?this.props.order.join("-"):"NONE"}`,
			this.props.isBase === undefined?true:this.props.isBase).then(
				(data) => {
					if (data.header && data.values) {
						this.setState(
							{ columns: data.header as any, data: data.values },
							() => {
								this.tableRef.current && this.tableRef.current.setLoading(false);
							}
						);
					}
				}
			);
	};

	onSave = (values: any, close: (state: boolean) => void, oldValues?: any) => {
		window.backend.postData(this.props.postUrl?this.props.postUrl:
			`${this.url}${this.props.table}`,
			{ data: values, oldData: oldValues },
			this.props.isBase === undefined?true:this.props.isBase).then(
				(data) => {
					if(data && data.title){
						this.setState({title: data.title, message: data.message}, () => {
							this.dialogRef.current?.open();
						});
					}
					this.loadTable();
					close(true);
				}
			);
	};

	onDelete = (values: any) => {
		window.backend.deleteData(this.props.deleteUrl?this.props.deleteUrl:
			`${this.url}${this.props.table}`,
			values, this.props.isBase === undefined?true:this.props.isBase).then(
				(data) => {
					this.loadTable();
				}
			);
	};

	componentDidMount() {
        console.log(this.props.isBase)
		this.loadTable();
	}

	render() {
		return (
			<>
				<Dialog
					ref={this.dialogRef}
					title={this.state.title} closeable>
					<div>{this.state.message}</div>
					<div style={{display: "flex", margin: "10px", justifyContent: "center"}}>
						<Button onClick={this.dialogRef.current?.close} type="success" text="Okay"></Button>
					</div>
				</Dialog>
				<Table
					ref={this.tableRef}
					columns={this.state.columns}
					data={this.state.data}
					deleteable
					editable
					onSave={this.onSave}
					onDelete={this.onDelete}
				></Table>
			</>
		);
	}
}
