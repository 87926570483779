import RouteManager from "../pages/adminTool/RouteManager";
import VerticalNavigationManager from "../pages/adminTool/VerticalNavigationManager";
import TableManager from "../pages/adminTool/TableManager";
import ApplicationManager from "../pages/adminTool/ApplicationManager";
import TablePostprocessManager from "../pages/adminTool/TablePostprocessManager";
import UserManager from "../pages/adminTool/UserManager";
import Auth0RoleManager from "../pages/adminTool/Auth0RoleManager";
import PageManager from "../pages/adminTool/PageManager";
import BasePage from "../base/BasePage";
import HorizontalNavigationManager from "../pages/adminTool/HorizontalNavigationManager";
import CustomersManager from "../pages/markuscustomers/CustomersManager"; 
import PermissionManager from "../pages/adminTool/PermissionManager";
import RoleManager from "../pages/adminTool/RoleManager";
import TablePermissionManager from "../pages/adminTool/TablePermissionsManager";
import AppUserManager from "../pages/all/AppUserManager";
import DatabaseSyncer from "../pages/databasetool/DatabaseSyncer";
import DatabaseDataSyncer from "../pages/databasetool/DatabaseDataSyncer";

interface pages{
    [name: string]: any;
}

export const PageList: pages = {
    BasePage,
    RouteManager,
    VerticalNavigationManager,
    TableManager,
    ApplicationManager,
    UserManager,
    RoleManager,
    Auth0RoleManager,
    PageManager,
    HorizontalNavigationManager,
    CustomersManager,
    TablePostprocessManager,
    PermissionManager,
    TablePermissionManager,
    AppUserManager,
    DatabaseSyncer,
    DatabaseDataSyncer
}